<template>
    <v-dialog v-model="versionDetailsDialog" width="70%" :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>{{ name }}</v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="2" class="hidden-sm-and-down">
                            <div>
                                <v-img class="mx-3" :src="iconUrl" contain></v-img>
                            </div>
                            <div class="mt-5">
                                <v-chip v-for="(tag, index) in selectedTags" :key="`selected-tag-${index}`" small class="mr-2 my-1" color="primary">
                                    {{ tag }}
                                </v-chip>
                                <v-chip v-for="(tag, index) in tagList" :key="`tag-${index}`" small class="mr-2 my-1">{{ tag }}</v-chip>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="10" class="overflow-y-auto" style="max-height: 70vh">
                            <viewer id="appVersionDescription" ref="appVersionDescription" initialValue="Fetching version details" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="versionDetailsDialog = false">close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { Viewer } from '@toast-ui/vue-editor'
import { analyticsMixin } from '@/mixins/analytics'

export default {
    name: 'VersionDescriptionViewer',
    components: { Viewer },
    mixins: [analyticsMixin],
    props: {
        name: String,
        iconUrl: String,
        descriptionUrl: String,
        disabled: {
            type: Boolean,
            default: false
        },
        selectedTags: Array,
        versionTags: Object
    },
    data() {
        return {
            versionDetailsDialog: false,
            versionDetails: ''
        }
    },
    computed: {
        tagList() {
            if (!this.versionTags) return []
            const filteredTags = []
            for (const [tagType, tagNames] of Object.entries(this.versionTags)) {
                const tagNamesArr = Array.isArray(tagNames) ? tagNames : [tagNames]
                tagNamesArr.forEach(tagName => {
                    const tag = `${tagType}:${tagName}`
                    if (!this.selectedTags.includes(tag)) filteredTags.push(tag)
                })
            }
            return filteredTags
        }
    },
    methods: {
        async fetchVersionMarkdown(url) {
            let description = 'No details available'
            if (!url) return description
            try {
                const descriptionFile = await window.fetch(url)
                description = await descriptionFile.text()
            } catch {
                description = 'We can not seem to get the details of this version. Please try again later.'
                this.versionDetails = ''
                this.sendErrorEvent(`App description error: ${this.name}`, {
                    message: 'Could not fetch',
                    url
                })
            }
            return description
        },
        async updateMarkdown() {
            this.versionDetails = this.versionDetails || (await this.fetchVersionMarkdown(this.descriptionUrl))
            this.$refs.appVersionDescription.invoke('setMarkdown', this.versionDetails)
        }
    },
    watch: {
        versionDetailsDialog(to) {
            if (!to) return
            this.updateMarkdown()
        }
    }
}
</script>

<style lang="scss" scoped>
// ::v-deep {
//     #appVersionDescription,
//     #appDescription {
//         .toastui-editor-contents {
//             h1,
//             h2,
//             h3,
//             h4,
//             h5,
//             h6 {
//                 border-bottom: none;
//             }
//         }
//     }
// }
</style>
